import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Navigation from './components/Navigation';
import HeroSection from './components/HeroSection';
import FashionHeritage from './components/FashionHeritage';
import BrandsComparison from './components/BrandsComparison';
import TrendingSection from './components/TrendingSection';
import FashionShows from './components/FashionShows';
import FeatureBlocks from './components/FeatureBlocks';
import ContactForm from './components/ContactForm';
import Footer from './components/Footer';

function App() {
  return (
    <Router>
      <div>
        <Navigation />
        <HeroSection />
        <FashionHeritage />
        <BrandsComparison />
        <TrendingSection />
        <FashionShows />
        <FeatureBlocks />
        <ContactForm />
        <Footer />
      </div>
    </Router>
  );
}

export default App;
