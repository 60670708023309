import React from 'react';

const FeatureBlocks = () => {
  const styles = {
    section: {
      padding: '4rem 0',
      backgroundColor: '#f8f8f8',
    },
    container: {
      maxWidth: '1200px',
      margin: '0 auto',
      padding: '0 1rem',
    },
    title: {
      fontSize: '2.5rem',
      textAlign: 'center',
      marginBottom: '3rem',
      color: '#333',
      fontFamily: 'Playfair Display, serif',
    },
    grid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(280px, 1fr))',
      gap: '2rem',
    },
    feature: {
      backgroundColor: '#fff',
      borderRadius: '8px',
      padding: '2rem',
      textAlign: 'center',
      boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
      transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    },
    icon: {
      fontSize: '2.5rem',
      color: '#f50057',
      marginBottom: '1rem',
    },
    title: {
      fontSize: '1.5rem',
      color: '#333',
      marginBottom: '1rem',
      fontFamily: 'Playfair Display, serif',
    },
    description: {
      color: '#666',
      lineHeight: '1.6',
      fontFamily: 'Arial, sans-serif',
    },
  };

  const features = [
    {
      icon: '👗',
      title: "Collections Exclusives",
      description: "Découvrez les dernières collections des plus prestigieuses maisons de mode françaises et italiennes."
    },
    {
      icon: '✨',
      title: "Haute Couture",
      description: "Explorez l'excellence du savoir-faire artisanal et des créations sur mesure."
    },
    {
      icon: '🎨',
      title: "Style Personnalisé",
      description: "Nos experts mode vous guident dans le choix des pièces qui sublimeront votre style."
    },
    {
      icon: '🌟',
      title: "Éditions Limitées",
      description: "Accédez à des pièces rares et des collaborations exclusives avec les plus grands créateurs."
    }
  ];

  return (
    <section style={styles.section}>
      <div style={styles.container}>
        <h2 style={styles.title}>L'Excellence de la Mode</h2>
        <div style={styles.grid}>
          {features.map((feature, index) => (
            <div
              key={index}
              style={styles.feature}
              onMouseEnter={e => {
                e.currentTarget.style.transform = 'translateY(-10px)';
                e.currentTarget.style.boxShadow = '0 4px 20px rgba(0,0,0,0.15)';
              }}
              onMouseLeave={e => {
                e.currentTarget.style.transform = 'translateY(0)';
                e.currentTarget.style.boxShadow = '0 2px 8px rgba(0,0,0,0.1)';
              }}
            >
              <div style={styles.icon}>{feature.icon}</div>
              <h3 style={styles.title}>{feature.title}</h3>
              <p style={styles.description}>{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FeatureBlocks;
