import React, { useState, useRef } from 'react';

const FashionHeritage = () => {
  const [selectedHouse, setSelectedHouse] = useState(null);
  const [activeTab, setActiveTab] = useState('histoire');
  const timelineRef = useRef(null);

  const handleScroll = (direction) => {
    if (timelineRef.current) {
      const scrollAmount = direction === 'left' ? -300 : 300;
      timelineRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  const styles = {
    container: {
      maxWidth: '1200px',
      margin: '0 auto',
      padding: '20px',
    },
    header: {
      textAlign: 'center',
      marginBottom: '40px',
    },
    title: {
      fontSize: '2.5rem',
      fontWeight: 'bold',
      marginBottom: '16px',
    },
    subtitle: {
      fontSize: '1.2rem',
      color: '#666',
    },
    timeline: {
      display: 'flex',
      overflowX: 'auto',
      gap: '20px',
      padding: '20px 0',
      marginBottom: '40px',
    },
    card: {
      flex: '0 0 300px',
      backgroundColor: '#fff',
      borderRadius: '8px',
      boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
      cursor: 'pointer',
      transition: 'transform 0.2s',
    },
    cardSelected: {
      boxShadow: '0 4px 12px rgba(0,0,0,0.2)',
      transform: 'translateY(-4px)',
    },
    cardImage: {
      height: '200px',
      borderRadius: '8px 8px 0 0',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      position: 'relative',
    },
    cardOverlay: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      padding: '20px',
      background: 'linear-gradient(to top, rgba(0,0,0,0.8), transparent)',
      color: '#fff',
    },
    cardContent: {
      padding: '20px',
    },
    mainContent: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: '40px',
      marginTop: '40px',
    },
    tabs: {
      display: 'flex',
      gap: '20px',
      marginBottom: '20px',
      borderBottom: '1px solid #eee',
    },
    tab: {
      padding: '10px 0',
      cursor: 'pointer',
      border: 'none',
      background: 'none',
      color: '#666',
      fontSize: '1rem',
      position: 'relative',
    },
    activeTab: {
      color: '#000',
      fontWeight: 'bold',
    },
    tabContent: {
      backgroundColor: '#fff',
      padding: '24px',
      borderRadius: '8px',
      boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
    }
  };

  const fashionHouses = [
    {
      id: 1,
      name: "Louis Vuitton",
      year: 1854,
      founder: "Louis Vuitton",
      location: "Paris, France",
      image: "/images/lv-heritage.jpg",
      signature: "L'art du voyage",
      histoire: {
        fondation: "Fondée à Paris par Louis Vuitton, ancien malletier de l'impératrice Eugénie.",
        evolution: "De la malle plate révolutionnaire aux collections de prêt-à-porter contemporaines.",
        heritage: "Plus de 150 ans d'innovation dans la maroquinerie de luxe."
      },
      expertise: {
        domaines: ["Maroquinerie", "Prêt-à-porter", "Joaillerie", "Horlogerie"],
        techniques: "Excellence artisanale dans le travail du cuir et des toiles monogram.",
        innovation: "Fusion entre tradition artisanale et design contemporain."
      },
      iconique: {
        produits: ["Malle Petite", "Sac Speedy", "Sac Neverfull"],
        motifs: "Le monogramme LV, la toile Damier",
        collaborations: "Collaborations artistiques prestigieuses avec Jeff Koons, Yayoi Kusama..."
      }
    },
    {
      id: 2,
      name: "Hermès",
      year: 1837,
      founder: "Thierry Hermès",
      location: "Paris, France",
      image: "/images/hermes-heritage.jpg",
      signature: "L'excellence artisanale",
      histoire: {
        fondation: "Créée comme atelier de harnais et de brides pour l'aristocratie parisienne.",
        evolution: "De la sellerie au luxe global, en conservant l'excellence artisanale.",
        heritage: "Six générations d'artisanat d'exception."
      },
      expertise: {
        domaines: ["Maroquinerie", "Soie", "Prêt-à-porter", "Art de vivre"],
        techniques: "Savoir-faire artisanal transmis de génération en génération.",
        innovation: "Création constante de nouveaux standards d'excellence."
      },
      iconique: {
        produits: ["Sac Kelly", "Sac Birkin", "Carré de soie"],
        motifs: "Les motifs équestres, la boucle Chaîne d'ancre",
        collaborations: "Création de pièces uniques sur mesure."
      }
    },
    {
      id: 3,
      name: "Gucci",
      year: 1921,
      founder: "Guccio Gucci",
      location: "Florence, Italie",
      image: "/images/gucci-heritage.jpg",
      signature: "L'audace créative italienne",
      histoire: {
        fondation: "Inspiré par le luxe de l'hôtel Savoy de Londres où il travaillait comme porteur.",
        evolution: "De la maroquinerie artisanale à l'empire du luxe moderne.",
        heritage: "Un siècle de créativité et d'innovation dans la mode."
      },
      expertise: {
        domaines: ["Maroquinerie", "Prêt-à-porter", "Accessoires", "Parfums"],
        techniques: "Fusion de l'artisanat traditionnel et du design contemporain.",
        innovation: "Réinvention constante des codes du luxe."
      },
      iconique: {
        produits: ["Sac Bamboo", "Mocassins à mors", "Sac Jackie"],
        motifs: "Le double G, la bande Web verte et rouge",
        collaborations: "Collaborations avant-gardistes avec des artistes contemporains."
      }
    },
    {
      id: 4,
      name: "Loro Piana",
      year: 1924,
      founder: "Pietro Loro Piana",
      location: "Quarona, Italie",
      image: "/images/loropiana-heritage.jpg",
      signature: "L'excellence des fibres nobles",
      histoire: {
        fondation: "Débute comme négociant en laine et tissus précieux.",
        evolution: "De la production de tissus aux vêtements de luxe.",
        heritage: "Expert mondial des fibres les plus rares."
      },
      expertise: {
        domaines: ["Tissus précieux", "Cachemire", "Prêt-à-porter", "Accessoires"],
        techniques: "Sélection et traitement des fibres les plus rares au monde.",
        innovation: "Développement de technologies textiles exclusives."
      },
      iconique: {
        produits: ["Baby Cachemire", "Tissus Storm System", "Vicuña"],
        motifs: "L'excellence discrète des matières naturelles",
        collaborations: "Partenariats avec les meilleurs éleveurs du monde."
      }
    },
    {
      id: 5,
      name: "Fendi",
      year: 1925,
      founder: "Adele et Edoardo Fendi",
      location: "Rome, Italie",
      image: "/images/fendi-heritage.jpg",
      signature: "L'artisanat romain",
      histoire: {
        fondation: "Débute comme atelier de fourrure et maroquinerie à Rome.",
        evolution: "Révolutionnée par Karl Lagerfeld, devenue icône du luxe moderne.",
        heritage: "L'excellence artisanale romaine au service de la mode."
      },
      expertise: {
        domaines: ["Fourrure", "Maroquinerie", "Prêt-à-porter", "Accessoires"],
        techniques: "Travail innovant de la fourrure et du cuir.",
        innovation: "Modernisation constante des techniques traditionnelles."
      },
      iconique: {
        produits: ["Sac Baguette", "Peekaboo", "Fourrures colorées"],
        motifs: "Le double F, les yeux Bag Bugs",
        collaborations: "Fusion unique entre tradition et modernité."
      }
    }
  ];

  const renderTabContent = (house) => {
    switch (activeTab) {
      case 'histoire':
        return (
          <div>
            <h3 style={{ fontSize: '1.2rem', fontWeight: 'bold', marginBottom: '16px' }}>Fondation</h3>
            <p style={{ marginBottom: '16px' }}>{house.histoire.fondation}</p>
            <h3 style={{ fontSize: '1.2rem', fontWeight: 'bold', marginBottom: '16px' }}>Évolution</h3>
            <p style={{ marginBottom: '16px' }}>{house.histoire.evolution}</p>
            <h3 style={{ fontSize: '1.2rem', fontWeight: 'bold', marginBottom: '16px' }}>Héritage</h3>
            <p>{house.histoire.heritage}</p>
          </div>
        );
      case 'expertise':
        return (
          <div>
            <h3 style={{ fontSize: '1.2rem', fontWeight: 'bold', marginBottom: '16px' }}>Domaines d'Excellence</h3>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px', marginBottom: '16px' }}>
              {house.expertise.domaines.map((domaine, index) => (
                <span
                  key={index}
                  style={{
                    padding: '4px 12px',
                    backgroundColor: '#f5f5f5',
                    borderRadius: '16px',
                    fontSize: '0.9rem'
                  }}
                >
                  {domaine}
                </span>
              ))}
            </div>
            <h3 style={{ fontSize: '1.2rem', fontWeight: 'bold', marginBottom: '16px' }}>Techniques</h3>
            <p style={{ marginBottom: '16px' }}>{house.expertise.techniques}</p>
            <h3 style={{ fontSize: '1.2rem', fontWeight: 'bold', marginBottom: '16px' }}>Innovation</h3>
            <p>{house.expertise.innovation}</p>
          </div>
        );
      case 'iconique':
        return (
          <div>
            <h3 style={{ fontSize: '1.2rem', fontWeight: 'bold', marginBottom: '16px' }}>Produits Emblématiques</h3>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '12px', marginBottom: '16px' }}>
              {house.iconique.produits.map((produit, index) => (
                <div
                  key={index}
                  style={{
                    padding: '12px',
                    backgroundColor: '#f5f5f5',
                    borderRadius: '8px',
                    fontSize: '0.9rem'
                  }}
                >
                  {produit}
                </div>
              ))}
            </div>
            <h3 style={{ fontSize: '1.2rem', fontWeight: 'bold', marginBottom: '16px' }}>Motifs Signature</h3>
            <p style={{ marginBottom: '16px' }}>{house.iconique.motifs}</p>
            <h3 style={{ fontSize: '1.2rem', fontWeight: 'bold', marginBottom: '16px' }}>Collaborations</h3>
            <p>{house.iconique.collaborations}</p>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div style={styles.container}>
      <header style={styles.header}>
        <h1 style={styles.title}>L'Héritage de la Mode</h1>
        <p style={styles.subtitle}>
          Découvrez l'histoire fascinante des plus grandes maisons de mode
        </p>
      </header>

      <div style={{ position: 'relative' }}>
        <button
          onClick={() => handleScroll('left')}
          style={{
            position: 'absolute',
            left: '-20px',
            top: '50%',
            transform: 'translateY(-50%)',
            zIndex: 10,
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            border: 'none',
            background: 'white',
            boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '20px',
            color: '#666',
            transition: 'all 0.2s'
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.transform = 'translateY(-50%) scale(1.1)';
            e.currentTarget.style.boxShadow = '0 4px 12px rgba(0,0,0,0.15)';
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.transform = 'translateY(-50%)';
            e.currentTarget.style.boxShadow = '0 2px 8px rgba(0,0,0,0.1)';
          }}
        >
          ←
        </button>

        <button
          onClick={() => handleScroll('right')}
          style={{
            position: 'absolute',
            right: '-20px',
            top: '50%',
            transform: 'translateY(-50%)',
            zIndex: 10,
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            border: 'none',
            background: 'white',
            boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '20px',
            color: '#666',
            transition: 'all 0.2s'
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.transform = 'translateY(-50%) scale(1.1)';
            e.currentTarget.style.boxShadow = '0 4px 12px rgba(0,0,0,0.15)';
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.transform = 'translateY(-50%)';
            e.currentTarget.style.boxShadow = '0 2px 8px rgba(0,0,0,0.1)';
          }}
        >
          →
        </button>

        <div 
          ref={timelineRef}
          style={{
            ...styles.timeline,
            scrollBehavior: 'smooth',
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': {
              display: 'none'
            }
          }}
        >
          {fashionHouses.map((house) => (
            <div
              key={house.id}
              style={{
                ...styles.card,
                ...(selectedHouse?.id === house.id ? styles.cardSelected : {})
              }}
              onClick={() => setSelectedHouse(house)}
            >
              <div
                style={{
                  ...styles.cardImage,
                  backgroundImage: `url(${house.image})`
                }}
              >
                <div style={styles.cardOverlay}>
                  <div style={{ fontSize: '0.9rem', marginBottom: '4px' }}>{house.year}</div>
                  <div style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>{house.name}</div>
                </div>
              </div>
              <div style={styles.cardContent}>
                <div style={{ fontSize: '0.9rem', color: '#666' }}>{house.location}</div>
                <div style={{ fontSize: '0.8rem', fontStyle: 'italic', marginTop: '8px', color: '#888' }}>
                  {house.signature}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {selectedHouse && (
        <div style={styles.mainContent}>
          <div style={{ ...styles.cardImage, height: '400px', backgroundImage: `url(${selectedHouse.image})` }}>
            <div style={styles.cardOverlay}>
              <h2 style={{ fontSize: '2rem', marginBottom: '8px' }}>{selectedHouse.name}</h2>
              <p style={{ fontSize: '1.2rem', marginBottom: '4px' }}>{selectedHouse.signature}</p>
              <p style={{ fontSize: '0.9rem' }}>{selectedHouse.location}</p>
            </div>
          </div>

          <div style={styles.tabContent}>
            <div style={styles.tabs}>
              {['histoire', 'expertise', 'iconique'].map((tab) => (
                <button
                  key={tab}
                  style={{
                    ...styles.tab,
                    ...(activeTab === tab ? styles.activeTab : {})
                  }}
                  onClick={() => setActiveTab(tab)}
                >
                  {tab.charAt(0).toUpperCase() + tab.slice(1)}
                </button>
              ))}
            </div>
            {renderTabContent(selectedHouse)}
          </div>
        </div>
      )}

      {!selectedHouse && (
        <div style={{ textAlign: 'center', padding: '40px', color: '#666' }}>
          <p style={{ fontSize: '1.2rem' }}>
            Sélectionnez une maison pour découvrir son histoire
          </p>
        </div>
      )}
    </div>
  );
};

export default FashionHeritage;
