import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

const HeroSection = () => {
  const mountRef = useRef(null);
  const controlsRef = useRef(null);

  useEffect(() => {
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    
    renderer.setSize(window.innerWidth, window.innerHeight);
    mountRef.current.appendChild(renderer.domElement);

    // Création d'un mannequin stylisé
    const geometry = new THREE.CylinderGeometry(0.5, 1, 4, 32);
    const material = new THREE.MeshPhongMaterial({ 
      color: 0x1a1a1a,
      shininess: 100,
      specular: 0xffffff
    });
    const mannequin = new THREE.Mesh(geometry, material);
    scene.add(mannequin);

    // Ajout de vêtements stylisés
    const dressGeometry = new THREE.ConeGeometry(1.2, 3, 32);
    const dressMaterial = new THREE.MeshPhongMaterial({
      color: 0xf50057,
      shininess: 30,
      specular: 0x444444
    });
    const dress = new THREE.Mesh(dressGeometry, dressMaterial);
    dress.position.y = -0.5;
    mannequin.add(dress);

    // Lumières
    const light1 = new THREE.DirectionalLight(0xffffff, 1);
    light1.position.set(0, 5, 5);
    scene.add(light1);

    const light2 = new THREE.AmbientLight(0x404040);
    scene.add(light2);

    camera.position.z = 8;

    controlsRef.current = new OrbitControls(camera, renderer.domElement);
    controlsRef.current.enableZoom = false;
    controlsRef.current.autoRotate = true;

    const animate = () => {
      requestAnimationFrame(animate);
      controlsRef.current.update();
      renderer.render(scene, camera);
    };
    animate();

    const handleResize = () => {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      mountRef.current?.removeChild(renderer.domElement);
    };
  }, []);

  const styles = {
    container: {
      position: 'relative',
      height: '100vh',
      overflow: 'hidden',
      backgroundColor: '#1a1a1a',
    },
    canvas: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
    content: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      textAlign: 'center',
      zIndex: 1,
      color: '#fff',
      textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
    },
    title: {
      fontSize: '4rem',
      marginBottom: '1rem',
      fontWeight: 'bold',
      fontFamily: 'Playfair Display, serif',
    },
    subtitle: {
      fontSize: '1.5rem',
      marginBottom: '2rem',
      fontFamily: 'Playfair Display, serif',
      fontStyle: 'italic',
    },
    button: {
      padding: '1rem 2rem',
      fontSize: '1.2rem',
      backgroundColor: '#f50057',
      color: '#fff',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
      transition: 'background-color 0.3s ease',
      fontFamily: 'Arial, sans-serif',
    },
  };

  return (
    <div style={styles.container}>
      <div ref={mountRef} style={styles.canvas} />
      <div style={styles.content}>
        <h1 style={styles.title}>Mania Wax</h1>
        <p style={styles.subtitle}>L'Art de la Mode Française et Italienne</p>
        <button 
          style={styles.button}
          onMouseEnter={e => e.target.style.backgroundColor = '#c51162'}
          onMouseLeave={e => e.target.style.backgroundColor = '#f50057'}
          onClick={() => window.location.href = '#collections'}
        >
          Découvrir nos Collections
        </button>
      </div>
    </div>
  );
};

export default HeroSection;
