import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  const styles = {
    footer: {
      backgroundColor: '#1a1a1a',
      color: '#fff',
      padding: '4rem 0',
    },
    container: {
      maxWidth: '1200px',
      margin: '0 auto',
      padding: '0 1rem',
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
      gap: '2rem',
    },
    section: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
    },
    title: {
      fontSize: '1.5rem',
      marginBottom: '1rem',
      color: '#fff',
      fontFamily: 'Playfair Display, serif',
    },
    link: {
      color: '#ccc',
      textDecoration: 'none',
      transition: 'color 0.3s ease',
      fontFamily: 'Arial, sans-serif',
    },
    text: {
      color: '#ccc',
      lineHeight: '1.6',
      fontFamily: 'Arial, sans-serif',
    },
    social: {
      display: 'flex',
      gap: '1rem',
      marginTop: '1rem',
    },
    socialIcon: {
      color: '#fff',
      fontSize: '1.5rem',
      textDecoration: 'none',
      transition: 'color 0.3s ease',
    },
    bottom: {
      borderTop: '1px solid #333',
      marginTop: '2rem',
      paddingTop: '2rem',
      textAlign: 'center',
      color: '#ccc',
      fontFamily: 'Arial, sans-serif',
    },
  };

  return (
    <footer style={styles.footer}>
      <div style={styles.container}>
        <div style={styles.section}>
          <h3 style={styles.title}>À propos</h3>
          <p style={styles.text}>
            Mania Wax est votre destination mode de luxe, présentant les plus prestigieuses maisons de couture françaises et italiennes.
          </p>
        </div>

        <div style={styles.section}>
          <h3 style={styles.title}>Collections</h3>
          <Link 
            to="/haute-couture" 
            style={styles.link}
            onMouseEnter={e => e.target.style.color = '#f50057'}
            onMouseLeave={e => e.target.style.color = '#ccc'}
          >
            Haute Couture
          </Link>
          <Link 
            to="/pret-a-porter" 
            style={styles.link}
            onMouseEnter={e => e.target.style.color = '#f50057'}
            onMouseLeave={e => e.target.style.color = '#ccc'}
          >
            Prêt-à-porter
          </Link>
          <Link 
            to="/accessoires" 
            style={styles.link}
            onMouseEnter={e => e.target.style.color = '#f50057'}
            onMouseLeave={e => e.target.style.color = '#ccc'}
          >
            Accessoires
          </Link>
        </div>

        <div style={styles.section}>
          <h3 style={styles.title}>Contact</h3>
          <p style={styles.text}>Email: contact@mania-wax.fr</p>
          <p style={styles.text}>Tél: +33 1 23 45 67 89</p>
          <p style={styles.text}>16 Avenue Montaigne, Paris</p>
        </div>

        <div style={styles.section}>
          <h3 style={styles.title}>Suivez-nous</h3>
          <div style={styles.social}>
            <a 
              href="https://instagram.com/maniawax" 
              target="_blank" 
              rel="noopener noreferrer"
              style={styles.socialIcon}
              onMouseEnter={e => e.target.style.color = '#f50057'}
              onMouseLeave={e => e.target.style.color = '#fff'}
            >
              <i className="fab fa-instagram"></i>
            </a>
            <a 
              href="https://pinterest.com/maniawax" 
              target="_blank" 
              rel="noopener noreferrer"
              style={styles.socialIcon}
              onMouseEnter={e => e.target.style.color = '#f50057'}
              onMouseLeave={e => e.target.style.color = '#fff'}
            >
              <i className="fab fa-pinterest"></i>
            </a>
          </div>
        </div>
      </div>

      <div style={styles.bottom}>
        <p> {new Date().getFullYear()} Mania Wax. L'Art de la Mode Française et Italienne.</p>
      </div>
    </footer>
  );
};

export default Footer;
