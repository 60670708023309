import React, { useState } from 'react';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const styles = {
    container: {
      maxWidth: '800px',
      margin: '0 auto',
      padding: '4rem 2rem',
    },
    title: {
      fontSize: '2.5rem',
      textAlign: 'center',
      marginBottom: '2rem',
      color: '#333',
      fontFamily: 'Playfair Display, serif',
    },
    subtitle: {
      fontSize: '1.2rem',
      textAlign: 'center',
      marginBottom: '3rem',
      color: '#666',
      fontFamily: 'Arial, sans-serif',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1.5rem',
    },
    inputGroup: {
      display: 'flex',
      flexDirection: 'column',
      gap: '0.5rem',
    },
    label: {
      color: '#333',
      fontSize: '1rem',
      fontFamily: 'Arial, sans-serif',
    },
    input: {
      padding: '0.8rem',
      borderRadius: '4px',
      border: '1px solid #ddd',
      fontSize: '1rem',
      transition: 'border-color 0.3s ease',
      fontFamily: 'Arial, sans-serif',
    },
    textarea: {
      padding: '0.8rem',
      borderRadius: '4px',
      border: '1px solid #ddd',
      fontSize: '1rem',
      minHeight: '150px',
      resize: 'vertical',
      transition: 'border-color 0.3s ease',
      fontFamily: 'Arial, sans-serif',
    },
    button: {
      padding: '1rem 2rem',
      backgroundColor: '#1a1a1a',
      color: 'white',
      border: 'none',
      borderRadius: '4px',
      fontSize: '1rem',
      cursor: 'pointer',
      transition: 'background-color 0.3s ease',
      fontFamily: 'Arial, sans-serif',
      alignSelf: 'flex-start',
    },
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Ajoutez ici la logique d'envoi du formulaire
    console.log('Form submitted:', formData);
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.title}>Contactez-nous</h2>
      <p style={styles.subtitle}>
        Pour toute demande concernant nos collections ou pour organiser un rendez-vous personnalisé
      </p>

      <form onSubmit={handleSubmit} style={styles.form}>
        <div style={styles.inputGroup}>
          <label htmlFor="name" style={styles.label}>Nom</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            style={styles.input}
            onFocus={e => e.target.style.borderColor = '#1a1a1a'}
            onBlur={e => e.target.style.borderColor = '#ddd'}
            required
          />
        </div>

        <div style={styles.inputGroup}>
          <label htmlFor="email" style={styles.label}>Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            style={styles.input}
            onFocus={e => e.target.style.borderColor = '#1a1a1a'}
            onBlur={e => e.target.style.borderColor = '#ddd'}
            required
          />
        </div>

        <div style={styles.inputGroup}>
          <label htmlFor="subject" style={styles.label}>Sujet</label>
          <input
            type="text"
            id="subject"
            name="subject"
            value={formData.subject}
            onChange={handleChange}
            style={styles.input}
            onFocus={e => e.target.style.borderColor = '#1a1a1a'}
            onBlur={e => e.target.style.borderColor = '#ddd'}
            required
          />
        </div>

        <div style={styles.inputGroup}>
          <label htmlFor="message" style={styles.label}>Message</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            style={styles.textarea}
            onFocus={e => e.target.style.borderColor = '#1a1a1a'}
            onBlur={e => e.target.style.borderColor = '#ddd'}
            required
          />
        </div>

        <button
          type="submit"
          style={styles.button}
          onMouseEnter={e => e.target.style.backgroundColor = '#333'}
          onMouseLeave={e => e.target.style.backgroundColor = '#1a1a1a'}
        >
          Envoyer
        </button>
      </form>
    </div>
  );
};

export default ContactForm;
