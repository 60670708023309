import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Navigation = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const navStyles = {
    header: {
      backgroundColor: '#fff',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      zIndex: 1000,
    },
    nav: {
      maxWidth: '1200px',
      margin: '0 auto',
      padding: '1rem',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    logo: {
      fontSize: '1.5rem',
      fontWeight: 'bold',
      color: '#1976d2',
      textDecoration: 'none',
    },
    menuButton: {
      display: 'none',
      '@media (max-width: 768px)': {
        display: 'block',
        background: 'none',
        border: 'none',
        fontSize: '1.5rem',
        cursor: 'pointer',
      },
    },
    navLinks: {
      display: 'flex',
      gap: '2rem',
      '@media (max-width: 768px)': {
        display: isMenuOpen ? 'flex' : 'none',
        flexDirection: 'column',
        position: 'absolute',
        top: '100%',
        left: 0,
        right: 0,
        backgroundColor: '#fff',
        padding: '1rem',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      },
    },
    navLink: {
      color: '#333',
      textDecoration: 'none',
      padding: '0.5rem',
      transition: 'color 0.3s ease',
    },
  };

  return (
    <header style={navStyles.header}>
      <nav style={navStyles.nav}>
        <Link to="/" style={navStyles.logo}>
          Mania Wax
        </Link>
        
        <button 
          style={navStyles.menuButton}
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          ☰
        </button>

        <div style={navStyles.navLinks}>
          <a 
            href="/blog"
            style={navStyles.navLink}
            onMouseEnter={e => e.target.style.color = '#1976d2'}
            onMouseLeave={e => e.target.style.color = '#333'}
          >
            Blog
          </a>
          <Link 
            to="/collections"
            style={navStyles.navLink}
            onMouseEnter={e => e.target.style.color = '#1976d2'}
            onMouseLeave={e => e.target.style.color = '#333'}
          >
            Collections
          </Link>
          <Link 
            to="/quiz"
            style={navStyles.navLink}
            onMouseEnter={e => e.target.style.color = '#1976d2'}
            onMouseLeave={e => e.target.style.color = '#333'}
          >
            Quiz Style
          </Link>
          <Link 
            to="/contact"
            style={navStyles.navLink}
            onMouseEnter={e => e.target.style.color = '#1976d2'}
            onMouseLeave={e => e.target.style.color = '#333'}
          >
            Contact
          </Link>
        </div>
      </nav>
    </header>
  );
};

export default Navigation;
