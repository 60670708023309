import React from 'react';

const TrendingSection = () => {
  const trends = [
    {
      id: 1,
      category: "Tendances Actuelles",
      title: "Le Retour du Wax Traditionnel",
      description: "Les motifs ancestraux revisités dans une approche contemporaine",
      highlights: ["Motifs géométriques", "Couleurs vibrantes", "Tissages traditionnels"]
    },
    {
      id: 2,
      category: "Innovation",
      title: "Wax Moderne",
      description: "L'alliance parfaite entre tradition africaine et design contemporain",
      highlights: ["Techniques modernes", "Durabilité", "Styles urbains"]
    },
    {
      id: 3,
      category: "Collections",
      title: "Éditions Limitées",
      description: "Des pièces uniques créées par nos artisans",
      highlights: ["Pièces exclusives", "Savoir-faire artisanal", "Créations sur mesure"]
    }
  ];

  const styles = {
    section: {
      padding: '80px 20px',
      maxWidth: '1200px',
      margin: '0 auto',
      backgroundColor: '#fff',
    },
    header: {
      textAlign: 'center',
      marginBottom: '60px',
    },
    title: {
      fontSize: '2.5rem',
      fontWeight: 'bold',
      marginBottom: '16px',
      color: '#1a1a1a',
    },
    subtitle: {
      fontSize: '1.2rem',
      color: '#666',
      maxWidth: '600px',
      margin: '0 auto',
    },
    grid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
      gap: '30px',
      padding: '20px 0',
    },
    card: {
      padding: '30px',
      backgroundColor: '#fff',
      borderRadius: '12px',
      boxShadow: '0 4px 20px rgba(0,0,0,0.06)',
      transition: 'transform 0.3s ease, box-shadow 0.3s ease',
      cursor: 'pointer',
    },
    cardHover: {
      transform: 'translateY(-5px)',
      boxShadow: '0 6px 25px rgba(0,0,0,0.1)',
    },
    category: {
      fontSize: '0.9rem',
      color: '#666',
      textTransform: 'uppercase',
      letterSpacing: '1px',
      marginBottom: '12px',
    },
    cardTitle: {
      fontSize: '1.5rem',
      fontWeight: 'bold',
      marginBottom: '16px',
      color: '#1a1a1a',
    },
    description: {
      fontSize: '1rem',
      color: '#666',
      lineHeight: '1.6',
      marginBottom: '20px',
    },
    highlightsList: {
      listStyle: 'none',
      padding: 0,
      margin: 0,
    },
    highlight: {
      display: 'flex',
      alignItems: 'center',
      fontSize: '0.95rem',
      color: '#4a4a4a',
      marginBottom: '8px',
    },
    bullet: {
      width: '6px',
      height: '6px',
      backgroundColor: '#666',
      borderRadius: '50%',
      marginRight: '12px',
    }
  };

  return (
    <section style={styles.section}>
      <header style={styles.header}>
        <h2 style={styles.title}>Tendances & Collections</h2>
        <p style={styles.subtitle}>
          Découvrez nos dernières créations et les tendances qui façonnent la mode Wax contemporaine
        </p>
      </header>

      <div style={styles.grid}>
        {trends.map((trend) => (
          <div
            key={trend.id}
            style={styles.card}
            onMouseEnter={(e) => {
              e.currentTarget.style.transform = 'translateY(-5px)';
              e.currentTarget.style.boxShadow = '0 6px 25px rgba(0,0,0,0.1)';
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.transform = 'translateY(0)';
              e.currentTarget.style.boxShadow = '0 4px 20px rgba(0,0,0,0.06)';
            }}
          >
            <div style={styles.category}>{trend.category}</div>
            <h3 style={styles.cardTitle}>{trend.title}</h3>
            <p style={styles.description}>{trend.description}</p>
            <ul style={styles.highlightsList}>
              {trend.highlights.map((highlight, index) => (
                <li key={index} style={styles.highlight}>
                  <span style={styles.bullet}></span>
                  {highlight}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </section>
  );
};

export default TrendingSection;
