import React, { useState, useEffect } from 'react';
import brandsData from '../data/luxuryBrands.json';

const BrandsComparison = () => {
  const [selectedCategories, setSelectedCategories] = useState([
    'name', 'founded', 'headquarters', 'specialties', 'iconic_products'
  ]);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: 'name', direction: 'asc' });
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [viewMode, setViewMode] = useState('grid'); // 'grid' ou 'table'
  const [hoveredBrand, setHoveredBrand] = useState(null);

  const styles = {
    section: {
      padding: '6rem 2rem',
      backgroundColor: '#fff',
      minHeight: '100vh',
    },
    container: {
      maxWidth: '1400px',
      margin: '0 auto',
    },
    header: {
      marginBottom: '3rem',
      textAlign: 'center',
      position: 'relative',
    },
    title: {
      fontSize: '2.5rem',
      color: '#1a1a1a',
      marginBottom: '1rem',
      fontFamily: 'Playfair Display, serif',
    },
    subtitle: {
      fontSize: '1.1rem',
      color: '#666',
      maxWidth: '600px',
      margin: '0 auto 2rem',
      lineHeight: '1.6',
    },
    controls: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '2rem',
      flexWrap: 'wrap',
      gap: '1rem',
      position: 'sticky',
      top: '0',
      backgroundColor: '#fff',
      padding: '1rem 0',
      zIndex: 10,
      borderBottom: '1px solid #eee',
    },
    search: {
      padding: '0.8rem 1rem',
      border: '1px solid #ddd',
      borderRadius: '4px',
      width: '300px',
      fontSize: '1rem',
      transition: 'all 0.3s ease',
      '&:focus': {
        borderColor: '#1a1a1a',
        outline: 'none',
        boxShadow: '0 0 0 2px rgba(26,26,26,0.1)',
      },
    },
    viewToggle: {
      display: 'flex',
      gap: '0.5rem',
      marginLeft: 'auto',
    },
    viewButton: {
      padding: '0.5rem 1rem',
      border: '1px solid #ddd',
      borderRadius: '4px',
      background: 'none',
      cursor: 'pointer',
      transition: 'all 0.3s ease',
      '&:hover': {
        backgroundColor: '#f5f5f5',
      },
    },
    viewButtonActive: {
      backgroundColor: '#1a1a1a',
      color: '#fff',
      border: '1px solid #1a1a1a',
    },
    categorySelect: {
      display: 'flex',
      gap: '0.5rem',
      flexWrap: 'wrap',
      marginBottom: '1rem',
    },
    categoryButton: {
      padding: '0.5rem 1rem',
      border: '1px solid #ddd',
      borderRadius: '20px',
      background: 'none',
      cursor: 'pointer',
      fontSize: '0.9rem',
      transition: 'all 0.3s ease',
      whiteSpace: 'nowrap',
    },
    categoryButtonActive: {
      backgroundColor: '#1a1a1a',
      color: '#fff',
      border: '1px solid #1a1a1a',
    },
    gridContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
      gap: '2rem',
      padding: '2rem 0',
    },
    gridCard: {
      backgroundColor: '#fff',
      borderRadius: '8px',
      padding: '2rem',
      boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
      transition: 'all 0.3s ease',
      cursor: 'pointer',
      position: 'relative',
      overflow: 'hidden',
    },
    gridCardHovered: {
      transform: 'translateY(-5px)',
      boxShadow: '0 8px 30px rgba(0,0,0,0.15)',
    },
    brandName: {
      fontSize: '1.5rem',
      marginBottom: '1rem',
      fontFamily: 'Playfair Display, serif',
    },
    brandInfo: {
      fontSize: '0.9rem',
      color: '#666',
      marginBottom: '0.5rem',
    },
    tableContainer: {
      overflowX: 'auto',
      boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
      borderRadius: '8px',
      backgroundColor: '#fff',
    },
    table: {
      width: '100%',
      borderCollapse: 'collapse',
      fontSize: '0.9rem',
    },
    th: {
      backgroundColor: '#1a1a1a',
      color: '#fff',
      padding: '1rem',
      textAlign: 'left',
      cursor: 'pointer',
      whiteSpace: 'nowrap',
      fontFamily: 'Playfair Display, serif',
      position: 'sticky',
      top: 0,
    },
    td: {
      padding: '1rem',
      borderBottom: '1px solid #eee',
      verticalAlign: 'top',
      transition: 'all 0.3s ease',
    },
    tr: {
      transition: 'all 0.3s ease',
      '&:hover': {
        backgroundColor: '#f9f9f9',
      },
    },
    list: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
    listItem: {
      marginBottom: '0.3rem',
      fontSize: '0.9rem',
      color: '#666',
    },
    badge: {
      display: 'inline-block',
      padding: '0.2rem 0.5rem',
      borderRadius: '12px',
      fontSize: '0.8rem',
      marginRight: '0.5rem',
      marginBottom: '0.5rem',
      backgroundColor: '#f0f0f0',
      color: '#666',
    },
    tooltip: {
      position: 'absolute',
      backgroundColor: 'rgba(0,0,0,0.8)',
      color: '#fff',
      padding: '0.5rem 1rem',
      borderRadius: '4px',
      fontSize: '0.8rem',
      zIndex: 100,
      maxWidth: '200px',
      pointerEvents: 'none',
    },
  };

  const categories = {
    name: 'Maison',
    founded: 'Fondation',
    founder: 'Fondateur',
    headquarters: 'Siège',
    parent: 'Groupe',
    revenue: 'Chiffre d\'affaires',
    specialties: 'Spécialités',
    iconic_products: 'Produits Iconiques',
    current_designer: 'Directeur Artistique',
    price_range: 'Gamme de Prix',
    stores: 'Boutiques',
    signature_materials: 'Matériaux Signature',
    sustainability: 'Développement Durable',
    artisanal_expertise: 'Expertise Artisanale',
    heritage: 'Héritage',
    flagship_store: 'Boutique Phare',
    key_markets: 'Marchés Clés'
  };

  const toggleCategory = (category) => {
    setSelectedCategories(prev => 
      prev.includes(category)
        ? prev.filter(c => c !== category)
        : [...prev, category]
    );
  };

  const handleSort = (key) => {
    setSortConfig(prev => ({
      key,
      direction: prev.key === key && prev.direction === 'asc' ? 'desc' : 'asc'
    }));
  };

  const sortedBrands = [...brandsData.brands].sort((a, b) => {
    if (sortConfig.key === 'founded' || sortConfig.key === 'stores') {
      return sortConfig.direction === 'asc' 
        ? a[sortConfig.key] - b[sortConfig.key]
        : b[sortConfig.key] - a[sortConfig.key];
    }
    
    const aValue = a[sortConfig.key]?.toString().toLowerCase();
    const bValue = b[sortConfig.key]?.toString().toLowerCase();
    
    if (sortConfig.direction === 'asc') {
      return aValue < bValue ? -1 : 1;
    } else {
      return aValue > bValue ? -1 : 1;
    }
  });

  const filteredBrands = sortedBrands.filter(brand => {
    const searchLower = searchTerm.toLowerCase();
    return Object.keys(brand).some(key => {
      const value = brand[key];
      if (Array.isArray(value)) {
        return value.some(v => v.toLowerCase().includes(searchLower));
      }
      return value.toString().toLowerCase().includes(searchLower);
    });
  });

  const renderCell = (brand, category) => {
    const value = brand[category];
    if (Array.isArray(value)) {
      return (
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '0.3rem' }}>
          {value.map((item, index) => (
            <span key={index} style={styles.badge}>{item}</span>
          ))}
        </div>
      );
    }
    return value;
  };

  const renderGridCard = (brand) => (
    <div
      key={brand.id}
      style={{
        ...styles.gridCard,
        ...(hoveredBrand === brand.id ? styles.gridCardHovered : {})
      }}
      onMouseEnter={() => setHoveredBrand(brand.id)}
      onMouseLeave={() => setHoveredBrand(null)}
    >
      <h3 style={styles.brandName}>{brand.name}</h3>
      {selectedCategories
        .filter(category => category !== 'name')
        .map(category => (
          <div key={category} style={styles.brandInfo}>
            <strong>{categories[category]}: </strong>
            {renderCell(brand, category)}
          </div>
        ))
      }
    </div>
  );

  return (
    <section style={styles.section}>
      <div style={styles.container}>
        <div style={styles.header}>
          <h2 style={styles.title}>Comparaison des Maisons de Luxe</h2>
          <p style={styles.subtitle}>
            Découvrez et comparez l'héritage, l'expertise et les spécificités des plus grandes maisons de luxe
          </p>
        </div>

        <div style={styles.controls}>
          <input
            type="text"
            placeholder="Rechercher une maison, un produit, une expertise..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={styles.search}
          />
          
          <div style={styles.viewToggle}>
            <button
              onClick={() => setViewMode('grid')}
              style={{
                ...styles.viewButton,
                ...(viewMode === 'grid' ? styles.viewButtonActive : {})
              }}
            >
              Grille
            </button>
            <button
              onClick={() => setViewMode('table')}
              style={{
                ...styles.viewButton,
                ...(viewMode === 'table' ? styles.viewButtonActive : {})
              }}
            >
              Tableau
            </button>
          </div>
        </div>

        <div style={styles.categorySelect}>
          {Object.entries(categories).map(([key, label]) => (
            <button
              key={key}
              onClick={() => toggleCategory(key)}
              style={{
                ...styles.categoryButton,
                ...(selectedCategories.includes(key) ? styles.categoryButtonActive : {})
              }}
            >
              {label}
            </button>
          ))}
        </div>

        {viewMode === 'grid' ? (
          <div style={styles.gridContainer}>
            {filteredBrands.map(brand => renderGridCard(brand))}
          </div>
        ) : (
          <div style={styles.tableContainer}>
            <table style={styles.table}>
              <thead>
                <tr>
                  {selectedCategories.map(category => (
                    <th
                      key={category}
                      onClick={() => handleSort(category)}
                      style={styles.th}
                    >
                      {categories[category]}
                      {sortConfig.key === category && (
                        <span style={{ marginLeft: '0.5rem', opacity: 0.5 }}>
                          {sortConfig.direction === 'asc' ? '↑' : '↓'}
                        </span>
                      )}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {filteredBrands.map(brand => (
                  <tr
                    key={brand.id}
                    style={styles.tr}
                    onMouseEnter={() => setHoveredBrand(brand.id)}
                    onMouseLeave={() => setHoveredBrand(null)}
                  >
                    {selectedCategories.map(category => (
                      <td
                        key={category}
                        style={{
                          ...styles.td,
                          backgroundColor: hoveredBrand === brand.id ? '#f9f9f9' : 'transparent'
                        }}
                      >
                        {renderCell(brand, category)}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </section>
  );
};

export default BrandsComparison;
