import React, { useState, useEffect } from 'react';

const FashionShows = () => {
  const [activeShow, setActiveShow] = useState(0);
  const [isPlaying, setIsPlaying] = useState(true);

  const styles = {
    section: {
      backgroundColor: '#000',
      color: '#fff',
      position: 'relative',
      overflow: 'hidden',
      minHeight: '100vh',
    },
    videoBackground: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      opacity: 0.6,
    },
    overlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'linear-gradient(to bottom, rgba(0,0,0,0.3), rgba(0,0,0,0.8))',
    },
    content: {
      position: 'relative',
      zIndex: 2,
      padding: '6rem 2rem',
      maxWidth: '1400px',
      margin: '0 auto',
    },
    header: {
      marginBottom: '4rem',
      textAlign: 'center',
    },
    title: {
      fontSize: '3.5rem',
      marginBottom: '1rem',
      fontFamily: 'Playfair Display, serif',
    },
    subtitle: {
      fontSize: '1.2rem',
      color: '#ccc',
      maxWidth: '600px',
      margin: '0 auto',
    },
    showsGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
      gap: '2rem',
      marginTop: '4rem',
    },
    show: {
      backgroundColor: 'rgba(255,255,255,0.1)',
      borderRadius: '8px',
      padding: '2rem',
      transition: 'all 0.3s ease',
      cursor: 'pointer',
      backdropFilter: 'blur(10px)',
    },
    showActive: {
      backgroundColor: 'rgba(255,255,255,0.2)',
      transform: 'scale(1.05)',
    },
    showDate: {
      color: '#f50057',
      fontSize: '1.1rem',
      marginBottom: '0.5rem',
      fontFamily: 'Arial, sans-serif',
    },
    showTitle: {
      fontSize: '1.8rem',
      marginBottom: '1rem',
      fontFamily: 'Playfair Display, serif',
    },
    showLocation: {
      color: '#ccc',
      fontSize: '1rem',
      marginBottom: '1rem',
      fontStyle: 'italic',
    },
    showDescription: {
      color: '#fff',
      lineHeight: '1.6',
    },
    timeline: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '1rem',
      marginTop: '4rem',
    },
    timelineItem: {
      width: '12px',
      height: '12px',
      borderRadius: '50%',
      backgroundColor: 'rgba(255,255,255,0.3)',
      cursor: 'pointer',
      transition: 'all 0.3s ease',
    },
    timelineItemActive: {
      backgroundColor: '#f50057',
      transform: 'scale(1.2)',
    },
    controls: {
      display: 'flex',
      justifyContent: 'center',
      gap: '1rem',
      marginTop: '2rem',
    },
    button: {
      background: 'none',
      border: '2px solid #fff',
      color: '#fff',
      padding: '0.8rem 1.5rem',
      borderRadius: '4px',
      cursor: 'pointer',
      fontSize: '1rem',
      transition: 'all 0.3s ease',
    },
  };

  const fashionShows = [
    {
      id: 1,
      title: "Louis Vuitton Femme Automne-Hiver 2024",
      date: "5 Mars 2024",
      location: "Cour Carrée du Louvre, Paris",
      description: "Nicolas Ghesquière présente sa vision de la femme moderne à travers une collection qui mêle héritage et innovation.",
      video: "/videos/lv-show-2024.mp4"
    },
    {
      id: 2,
      title: "Gucci Printemps-Été 2024",
      date: "22 Septembre 2023",
      location: "Palazzo della Civiltà Italiana, Rome",
      description: "Une collection qui célèbre l'artisanat italien et redéfinit les codes du luxe contemporain.",
      video: "/videos/gucci-show-2024.mp4"
    },
    {
      id: 3,
      title: "Hermès Haute Couture 2024",
      date: "23 Janvier 2024",
      location: "Garde Républicaine, Paris",
      description: "L'excellence artisanale d'Hermès sublimée dans une collection haute couture exceptionnelle.",
      video: "/videos/hermes-show-2024.mp4"
    }
  ];

  useEffect(() => {
    if (isPlaying) {
      const timer = setInterval(() => {
        setActiveShow((prev) => (prev + 1) % fashionShows.length);
      }, 8000);
      return () => clearInterval(timer);
    }
  }, [isPlaying]);

  return (
    <section style={styles.section}>
      <video
        style={styles.videoBackground}
        src={fashionShows[activeShow].video}
        autoPlay
        muted
        loop
      />
      <div style={styles.overlay} />
      
      <div style={styles.content}>
        <div style={styles.header}>
          <h2 style={styles.title}>Défilés & Événements</h2>
          <p style={styles.subtitle}>
            Vivez l'expérience des plus grands défilés de mode en direct des capitales du luxe
          </p>
        </div>

        <div style={styles.showsGrid}>
          {fashionShows.map((show, index) => (
            <div
              key={show.id}
              style={{
                ...styles.show,
                ...(index === activeShow ? styles.showActive : {})
              }}
              onClick={() => setActiveShow(index)}
              onMouseEnter={e => {
                e.currentTarget.style.backgroundColor = 'rgba(255,255,255,0.15)';
              }}
              onMouseLeave={e => {
                e.currentTarget.style.backgroundColor = index === activeShow ? 
                  'rgba(255,255,255,0.2)' : 'rgba(255,255,255,0.1)';
              }}
            >
              <p style={styles.showDate}>{show.date}</p>
              <h3 style={styles.showTitle}>{show.title}</h3>
              <p style={styles.showLocation}>{show.location}</p>
              <p style={styles.showDescription}>{show.description}</p>
            </div>
          ))}
        </div>

        <div style={styles.timeline}>
          {fashionShows.map((_, index) => (
            <div
              key={index}
              style={{
                ...styles.timelineItem,
                ...(index === activeShow ? styles.timelineItemActive : {})
              }}
              onClick={() => setActiveShow(index)}
            />
          ))}
        </div>

        <div style={styles.controls}>
          <button
            style={styles.button}
            onClick={() => setIsPlaying(!isPlaying)}
            onMouseEnter={e => {
              e.target.style.backgroundColor = '#fff';
              e.target.style.color = '#000';
            }}
            onMouseLeave={e => {
              e.target.style.backgroundColor = 'transparent';
              e.target.style.color = '#fff';
            }}
          >
            {isPlaying ? 'Pause' : 'Play'}
          </button>
        </div>
      </div>
    </section>
  );
};

export default FashionShows;
